import axios from "axios";

import { ApiOptions, generateAxiosRequestConfig } from "./misc/generateAxiosRequestConfig";

/** サブドメインの有効性検証時のリクエストペイロード */
export type PostCheckSubdomainAvailabilityRequest = {
	/** 検証サブドメイン */
	subdomain: string;
};

/** サブドメインの有効性検証時のレスポンスペイロード */
export type PostCheckSubdomainAvailabilityResponse = {
	/** 検証サブドメイン */
	subdomain: string;
	/** 有効性検証結果 */
	is_available: boolean;
	/** 詳細内容 */
	reason?: string;
};

/**
 * サブドメインの有効性を確認します
 *
 * @param reqData - 検証するサブドメイン
 * @param options - API オプション
 */
export const postCheckSubdomainAvailability = async (
	reqData: PostCheckSubdomainAvailabilityRequest,
	options: ApiOptions
): Promise<PostCheckSubdomainAvailabilityResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);
	const res = await axios.post(`/check-subdomain-availability`, reqData, reqConfig);

	return res.data;
};
