import { VFC } from "react";

import { Box, Grid, Typography } from "@mui/material";

import { ListedWorkspace } from "../typings/WorkspaceTypes";

import WorkspaceListCard from "./WorkspaceListCard";

type Props = {
	workspace: ListedWorkspace;
};

const WorkspaceSetupSuccessCardContent: VFC<Props> = ({ workspace }) => {
	return (
		<Box data-testid="workspace-setup-success-card-content-root">
			<Box display="flex" flexDirection="column" alignItems="center" mb={2}>
				<Typography variant="h3" component="span" sx={{ fontWeight: "bold" }}>
					おめでとうございます！
				</Typography>
				<Typography variant="h3" component="span" sx={{ fontWeight: "bold" }}>
					ワークスペースが完成しました！
				</Typography>
			</Box>
			<Box>
				<Grid container justifyContent="center" my={2}>
					<Grid item xs={6}>
						<Box p={2}>
							<Box component="img" width="100%" src={`${process.env.PUBLIC_URL}/assets/images/success.svg`} alt="" />
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box display="flex" flexDirection="column" alignItems="center" mt={2}>
				<Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
					ワークスペースを開く
				</Typography>
			</Box>
			<Box mt={2}>
				<Grid container justifyContent="center">
					<Grid item xs={8}>
						<WorkspaceListCard variant="outlined" workspaces={[workspace]} />
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default WorkspaceSetupSuccessCardContent;
