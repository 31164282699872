import { createTheme } from "@mui/material";

export const AppBarHeight = 64;
export const DrawerWidth = 240;

// 全体に適用するフォントファミリーの優先順位
const FontFamilies = ["Helvetica Neue", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif"];

const theme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "rgba(58,142,132,1)",
			contrastText: "rgba(250,250,250,1)",
		},
		secondary: {
			main: "#61B9CC",
			contrastText: "rgba(250,250,250,1)",
		},
		text: {
			primary: "#1a1a1a",
		},
		error: {
			main: "rgba(229,74,71,1)",
			contrastText: "rgba(250,250,250,1)",
		},
		background: {
			default: "rgba(230, 238, 237,1)",
		},
	},
	typography: {
		fontFamily: "'" + FontFamilies.join("','") + "'",
		fontSize: 12,
	},
	components: {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					backgroundColor: "rgba(0, 0, 0, 0.25)",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				startIcon: {
					marginRight: 4,
					marginBottom: 1,
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					"&:last-child": {
						paddingBottom: 16,
					},
				},
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					paddingTop: 14,
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					textDecoration: "none",
				},
			},
		},
		MuiStepConnector: {
			styleOverrides: {
				vertical: {
					marginLeft: 10, // ステップ間の縦線 位置調整
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				labelContainer: {
					paddingTop: "0.125em", // ステップラベル 位置調整
				},
			},
		},
	},
});

export { theme };
