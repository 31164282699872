import { VFC } from "react";

import { Box, Card, CardContent, CardProps, CircularProgress, Grid, Typography } from "@mui/material";

import { LoadingStatus } from "../typings/CommonTypes";
import { ListedWorkspace } from "../typings/WorkspaceTypes";

import WorkspaceSetupSuccessCardContent from "./WorkspaceSetupSuccessCardContent";

type Props = CardProps & {
	loading: LoadingStatus;
	workspace: ListedWorkspace | undefined;
};

const WorkspaceSetupSuccessCard: VFC<Props> = ({ loading, workspace, ...args }) => {
	return (
		<Card data-testid="workspace-setup-success-card-root" {...args}>
			<CardContent>
				<Box my={4}>
					{loading === LoadingStatus.Initial || loading === LoadingStatus.Pending ? (
						<Grid container direction="column" spacing={3} justifyContent="center" alignItems="center">
							<Grid item>
								<Typography variant="body1">ワークスペースの作成を確認しています</Typography>
							</Grid>
							<Grid item>
								<CircularProgress />
							</Grid>
						</Grid>
					) : loading === LoadingStatus.Error || !workspace ? (
						<Grid container direction="column" spacing={3} justifyContent="center" alignItems="center">
							<Grid item>
								<Typography variant="body1">ワークスペースが見つかりません</Typography>
							</Grid>
						</Grid>
					) : (
						<Box data-testid="workspace-setup-success-card-content">
							<WorkspaceSetupSuccessCardContent workspace={workspace} />
						</Box>
					)}
				</Box>
			</CardContent>
		</Card>
	);
};

export default WorkspaceSetupSuccessCard;
