import { VFC } from "react";

import { Link, Typography } from "@mui/material";

const CopyrightCollaboStyle: VFC = () => {
	return (
		<Typography variant="body2" color="textSecondary" align="center" data-testid="copyright-collabostyle-root">
			<Link color="inherit" href="https://www.collabo-style.co.jp/">
				{`© COLLABO STYLE INC. ${new Date().getFullYear()}`}
			</Link>
		</Typography>
	);
};

export default CopyrightCollaboStyle;
