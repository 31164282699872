import { Dispatch, ReactNode, SetStateAction, useState, VFC } from "react";
import { Redirect } from "react-router";

import { Box, Typography } from "@mui/material";

import { WorkspaceInfo } from "../typings/WorkspaceTypes";

import SubdomainChangeWizardContent from "./SubdomainChangeWizardContent";
import WorkspaceNameChangeWizardContent from "./WorkspaceNameChangeWizardContent";

type Props = {
	workspace: WorkspaceInfo;
	setWorkspace: Dispatch<SetStateAction<WorkspaceInfo | undefined>>;
};

const WorkspaceSetupWizard: VFC<Props> = ({ workspace, setWorkspace }) => {
	const steps = ["ワークスペース名を決める", "サブドメイン名を決める"];
	const maxStep = steps.length;
	const [activeStep, setActiveStep] = useState<number>(1);
	const [ending, setEnding] = useState(false);

	const nextStep = () => {
		if (activeStep < maxStep) {
			setActiveStep(activeStep + 1);
		} else {
			setEnding(true);
		}
	};

	const backStep = () => {
		if (1 < activeStep) {
			setActiveStep(activeStep - 1);
		}
	};

	const wizardContent = (step: number): ReactNode => {
		switch (step) {
			case 1:
				return <WorkspaceNameChangeWizardContent workspace={workspace} setWorkspace={setWorkspace} onNextStep={nextStep} />;
			case 2:
				return (
					<SubdomainChangeWizardContent
						subdomain={workspace.subdomains?.current?.name || ""}
						workspace_id={workspace.id}
						onNextStep={nextStep}
						onBackStep={backStep}
					/>
				);
			default:
				return;
		}
	};

	if (ending) {
		return <Redirect to={`/setup-workspace/${workspace.id}/success`} />;
	}

	return (
		<Box data-testid="workspace-setup-wizard-root">
			<Box>
				<Typography variant="body1">
					ステップ {activeStep}/{maxStep}
				</Typography>
			</Box>
			<Box mt={1}>{wizardContent(activeStep)}</Box>
		</Box>
	);
};

export default WorkspaceSetupWizard;
