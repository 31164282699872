import { useEffect, useState, VFC } from "react";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";

import { Grid } from "@mui/material";

import { getWorkspace } from "../apis/workspaces/get";
import ToastContent from "../components/ToastContent";
import WorkspaceSetupCard from "../components/WorkspaceSetupCard";
import MainTemplate from "../templates/MainTemplate";
import { LoadingStatus } from "../typings/CommonTypes";
import { WorkspaceInfo } from "../typings/WorkspaceTypes";

const SetupWorkspace: VFC<RouteComponentProps<{ workspace_id: string }>> = ({ match }) => {
	const workspace_id = match.params.workspace_id;

	const { getAccessTokenSilently, isAuthenticated } = useAuth0();
	const [workspace, setWorkspace] = useState<WorkspaceInfo>();
	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	useEffect(() => {
		if (!isAuthenticated || loading !== LoadingStatus.Initial) return;

		(async () => {
			await getWorkspace({ auth_token: await getAccessTokenSilently({ ignoreCache: true }), workspace_id })
				.then((workspace) => {
					setWorkspace(workspace);
					setLoading(LoadingStatus.Loaded);
				})
				.catch((reason) => {
					console.error(reason);
					toast(<ToastContent subject="ワークスペースを取得できませんでした" message={reason.message} />, {
						type: "error",
						autoClose: false,
					});
					setLoading(LoadingStatus.Error);
				});
		})();
	}, [getAccessTokenSilently, isAuthenticated, loading, workspace_id]);

	return (
		<MainTemplate>
			<Grid container spacing={0} justifyContent="center" data-testid="setup-workspace-root">
				<Grid item xs={10}>
					<WorkspaceSetupCard elevation={4} workspace={workspace} setWorkspace={setWorkspace} loading={loading} data-testid="setup-workspace-card" />
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default SetupWorkspace;
