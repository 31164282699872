import { VFC } from "react";
import { RouteProps } from "react-router";
import { Route } from "react-router-dom";

import { withAuthenticationRequired } from "@auth0/auth0-react";

type Props = RouteProps & {
	component: React.ComponentType<any>;
};

const ProtectedRoute: VFC<Props> = ({ component, ...args }) => {
	return (
		<Route
			component={withAuthenticationRequired(component, {
				returnTo: () => window.location.hash.substr(1),
				onRedirecting: () => <div>お待ちください...</div>,
			})}
			{...args}
		/>
	);
};

export default ProtectedRoute;
