import { ReactNode, VFC } from "react";

import { Box, Card, CardProps, Divider } from "@mui/material";

import { ListedWorkspace } from "../typings/WorkspaceTypes";

import WorkspaceListCardItem from "./WorkspaceListCardItem";

type Props = CardProps & {
	cardHeader?: ReactNode;
	workspaces?: ListedWorkspace[];
};

const WorkspaceListCard: VFC<Props> = ({ workspaces, cardHeader, ...args }) => {
	if (!workspaces) {
		return <></>;
	}

	return (
		<Card data-testid="workspace-list-card-root" {...args}>
			{cardHeader && (
				<Box data-testid="workspace-list-card-header">
					{cardHeader}
					<Divider />
				</Box>
			)}
			{workspaces.map((workspace, idx) => (
				<Box key={idx}>
					{idx ? <Divider key={`divider-${idx}`} /> : <></>}
					<WorkspaceListCardItem workspace={workspace} key={`item-${idx}`} />
				</Box>
			))}
		</Card>
	);
};

export default WorkspaceListCard;
