import { useEffect, useState, VFC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";

import { Box, CircularProgress, Grid, Typography, Link } from "@mui/material";

import { listWorkspaces } from "../apis/workspaces/list";
import { theme } from "../components/Theme";
import ToastContent from "../components/ToastContent";
import WorkspaceListCard from "../components/WorkspaceListCard";
import MainTemplate from "../templates/MainTemplate";
import { ListedWorkspace } from "../typings/WorkspaceTypes";

const Landing: VFC = () => {
	const { user, getAccessTokenSilently } = useAuth0();
	const [workspaces, setWorkspaces] = useState<ListedWorkspace[] | null>(null);

	useEffect(() => {
		if (workspaces) return;

		(async () => {
			await listWorkspaces({ auth_token: await getAccessTokenSilently() })
				.then(({ workspaces }) => {
					setWorkspaces(workspaces);
				})
				.catch((reason) => {
					console.error(reason);
					toast(<ToastContent subject="ワークスペースを取得できませんでした" message={reason.message} />, {
						type: "error",
						autoClose: false,
					});
					setWorkspaces([]);
				});
		})();
	}, [getAccessTokenSilently, workspaces]);

	return (
		<MainTemplate>
			<Grid container spacing={0} justifyContent="center" data-testid="landing-page-root">
				<Grid item xs={8}>
					{workspaces === null ? (
						<Box mt={4} display="flex" flexDirection="column" alignItems="center" data-testid="landing-page-workspaces-loading">
							<Box mb={3}>
								<Typography variant="body1">ワークスペースを読み込み中</Typography>
							</Box>
							<CircularProgress />
						</Box>
					) : (
						<Box>
							{workspaces.length ? (
								<Box mt={3} data-testid="landing-page-workspaces-loaded">
									<Box mb={2} display="flex" flexDirection="column" alignItems="center">
										<Typography component="p" variant="h4">
											<b>ワークスペースを開く</b>
										</Typography>
									</Box>
									<WorkspaceListCard
										elevation={5}
										workspaces={workspaces}
										cardHeader={
											<Box
												px={3}
												py={2.5}
												sx={{
													color: theme.palette.primary.contrastText,
													background: theme.palette.primary.main,
												}}
											>
												<Typography variant="body1">
													<b>{user.email}</b> のワークスペース
												</Typography>
											</Box>
										}
									/>
								</Box>
							) : (
								<Box mt={4} display="flex" flexDirection="column" alignItems="center" data-testid="landing-page-no-workspaces">
									<Typography component="p" variant="h6">
										<b>ワークスペースが見つかりません</b>
									</Typography>
								</Box>
							)}

							<Box mt={4} display="flex" flexDirection="column" alignItems="center">
								<Typography variant="body1">
									ワークスペースが表示されませんか？{" "}
									<Link component={RouterLink} to="/signin" sx={{ fontWeight: "bold" }} data-testid="landing-page-signin-link">
										別のアカウントに切り替える
									</Link>
								</Typography>
							</Box>
						</Box>
					)}
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default Landing;
