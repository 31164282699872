import axios from "axios";

import { ListedWorkspace } from "../../typings/WorkspaceTypes";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/** ワークスペースリスト取得のレスポンスペイロード */
export type ListWorkspacesResponse = {
	workspaces: ListedWorkspace[];
};
/**
 * ワークスペースのリストを取得します
 *
 * @param options API オプション
 */
export const listWorkspaces = async (options: ApiOptions): Promise<ListWorkspacesResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.get("/workspaces", reqConfig);
	return data;
};
