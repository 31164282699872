import axios from "axios";

import { Workspace } from "../../typings/WorkspaceTypes";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/**
 * ワークスペース更新リクエスト
 */
export type PatchWorkspacesRequestBody = {
	name?: string;
	is_active?: boolean;
	description?: string;
	logo_picture?: string;
};

/**
 * ワークスペース更新レスポンス
 */
export type PatchWorkspacesResponseBody = {
	id?: string;
	name?: string;
	description?: string;
	is_active?: boolean;
	logo_picture?: string;
	created_at?: string;
	updated_at?: string;
};

/**
 * ワークスペースを更新します
 *
 * @param reqData
 * @param options API オプション
 */
export const patchWorkspace = async (reqData: PatchWorkspacesRequestBody, options: Required<ApiOptions>): Promise<Workspace> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.patch<PatchWorkspacesResponseBody>(`/workspaces/${options.workspace_id}`, reqData, reqConfig);

	const { created_at, updated_at, ...args } = data;
	const res: Workspace = { ...args };
	created_at !== undefined && (res.created_at = new Date(created_at));
	updated_at !== undefined && (res.updated_at = new Date(updated_at));

	return res;
};
