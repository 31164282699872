import axios from "axios";

import { WorkspaceInfo } from "../../typings/WorkspaceTypes";
import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/**
 * ワークスペース取得レスポンス
 */
export type GetWorkspacesResponseBody = Omit<WorkspaceInfo, "created_at" | "updated_at"> & {
	created_at: string;
	updated_at: string;
};

/**
 * ワークスペースを取得します
 *
 * @param options API オプション
 */
export const getWorkspace = async (options: Required<ApiOptions>): Promise<WorkspaceInfo> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.get<GetWorkspacesResponseBody>(`/workspaces/${options.workspace_id}`, reqConfig);

	const { created_at, updated_at, ...args } = data;
	const res: WorkspaceInfo = { ...args };
	created_at !== undefined && (res.created_at = new Date(created_at));
	updated_at !== undefined && (res.updated_at = new Date(updated_at));

	return res;
};
