import { ReactNode, VFC } from "react";

import { Container, CssBaseline, Box } from "@mui/material";

import MainFooterContent from "../components/MainFooterContent";
import MainHeaderContent from "../components/MainHeaderContent";

type Props = {
	children: ReactNode;
	headerContent?: ReactNode;
	footerContent?: ReactNode;
};

const MainTemplate: VFC<Props> = ({ children, headerContent = <MainHeaderContent />, footerContent = <MainFooterContent /> }) => {
	return (
		<Box data-testid="main-template-root">
			<CssBaseline />
			<Box component="header" data-testid="main-template-header">
				{headerContent}
			</Box>
			<Box component="main" data-testid="main-template-main">
				<Container maxWidth="md">
					<>{children}</>
				</Container>
			</Box>
			<Box component="footer" data-testid="main-template-footer">
				{footerContent}
			</Box>
		</Box>
	);
};

export default MainTemplate;
