/**
 * ロード時の進捗状態
 */
export const LoadingStatus = {
	Initial: "initial",
	Pending: "pending",
	Loaded: "loaded",
	Updated: "updated",
	Error: "error",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LoadingStatus = typeof LoadingStatus[keyof typeof LoadingStatus];
