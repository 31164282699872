import { VFC } from "react";

import { Box } from "@mui/material";

import CopyrightCollaboStyle from "./CopyrightCollaboStyle";

const MainFooterContent: VFC = () => {
	return (
		<Box my={4} data-testid="main-footer-content-root">
			<CopyrightCollaboStyle />
		</Box>
	);
};

export default MainFooterContent;
