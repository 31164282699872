import { ReactNode, useState, VFC } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";

import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, InputAdornment, TextField, Typography } from "@mui/material";

import { postCheckSubdomainAvailability, PostCheckSubdomainAvailabilityResponse } from "../apis/check-subdomain-availability";
import { changeSubdomain, ChangeSubdomainRequestBody } from "../apis/subdomains/ChangeSubdomain";
import { LoadingStatus } from "../typings/CommonTypes";
import { StringConverter } from "../utils/StringConverter";

import ToastContent from "./ToastContent";

type FormState = {
	subdomain: string;
};

type Props = {
	subdomain: string;
	workspace_id: string;
	onNextStep: () => void;
	onBackStep: () => void;
};

const SubdomainChangeWizardContent: VFC<Props> = ({ subdomain, workspace_id, onNextStep, onBackStep }) => {
	const { getAccessTokenSilently } = useAuth0();
	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);
	const { errors, formState, handleSubmit, register, getValues, setValue } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: { subdomain },
	});
	const { isDirty, isValid } = formState;

	const [availabilityLoading, setAvailabilityLoading] = useState<LoadingStatus>(LoadingStatus.Initial);
	const [availability, setAvailability] = useState<PostCheckSubdomainAvailabilityResponse | null>(null);

	const onBlur = (): void => {
		const inputValue = getValues("subdomain");

		if (inputValue) {
			let newSubdomain = inputValue;
			const converter = new StringConverter();
			newSubdomain = converter.convert(newSubdomain, ["trim", "to_narrow", "to_lower"]);
			newSubdomain = newSubdomain.replace(" ", "");
			setValue("subdomain", newSubdomain);

			if (subdomain === inputValue) return;

			(async () => {
				setAvailabilityLoading(LoadingStatus.Pending);
				postCheckSubdomainAvailability({ subdomain: newSubdomain }, { auth_token: await getAccessTokenSilently(), workspace_id })
					.then((res) => {
						setAvailability(res);
						setAvailabilityLoading(LoadingStatus.Loaded);
					})
					.catch((reason) => {
						console.error(reason);
						toast(<ToastContent subject="サブドメインの有効性確認に失敗しました" message={reason.message} />, {
							type: toast.TYPE.ERROR,
							autoClose: 5000,
						});
					});
			})();
		}
	};

	const onSubmit = handleSubmit(async (data: any) => {
		if (!isDirty || getValues("subdomain") === subdomain) {
			onNextStep();
			return;
		}

		setLoading(LoadingStatus.Pending);

		const reqData: ChangeSubdomainRequestBody = {
			name: data.subdomain,
		};

		await changeSubdomain(reqData, { auth_token: await getAccessTokenSilently(), workspace_id })
			.then((_res) => {
				setLoading(LoadingStatus.Loaded);
				onNextStep();
			})
			.catch((reason) => {
				setLoading(LoadingStatus.Error);
				toast(<ToastContent subject="サブドメインの更新に失敗しました" message={reason.message} />, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
				});
			});
	});

	const disabled =
		isDirty && ([LoadingStatus.Loaded, LoadingStatus.Pending, LoadingStatus.Error].includes(loading as any) || !isValid || !availability?.is_available);

	const checkAvailabilityIcon = (): ReactNode => {
		if (!isDirty) return;
		if (availabilityLoading === LoadingStatus.Pending) {
			return <CircularProgress size={24} />;
		} else if (availability?.is_available || subdomain === getValues("subdomain")) {
			return <CheckIcon fontSize="large" color="primary" />;
		} else if (!isValid || (availability && !availability.is_available && availability.subdomain !== subdomain)) {
			return <BlockIcon fontSize="large" color="error" />;
		}
		return;
	};

	const helperText = (): ReactNode => {
		if (errors.subdomain?.message) {
			return (
				<Typography variant="subtitle2" color="error" component="span">
					{errors.subdomain?.message}
				</Typography>
			);
		} else if (availability?.is_available) {
			return (
				<Typography variant="subtitle2" color="primary" component="span">
					使用可能なサブドメインです
				</Typography>
			);
		} else if (availability === null || subdomain === getValues("subdomain")) {
			return (
				<Typography variant="subtitle2" component="span">
					3 ～ 40 文字以内で好きなサブドメインを入力してください
				</Typography>
			);
		} else if (availability?.reason && subdomain !== getValues("subdomain")) {
			return (
				<Typography variant="subtitle2" color="error" component="span">
					{availability?.reason}
				</Typography>
			);
		}
		return;
	};

	return (
		<Box data-testid="subdomain-change-wizard-content-root">
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={9}>
						<Box mr={1}>
							<Box>
								<Typography component="p" variant="h4">
									<strong>サブドメインを教えてください。</strong>
								</Typography>
							</Box>
							<Box my={1}>
								<Typography component="p" variant="body1" sx={{ fontSize: "1.25em" }}>
									サブドメインはあなたのワークスペースにアクセスするためのアドレスの一部になります。
								</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={3}>
						<Box p={2}>
							<Box component="img" width="100%" src={`${process.env.PUBLIC_URL}/assets/images/subdomain.svg`} alt="" />
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box mt={1}>
				<Box component="form" onSubmit={onSubmit} data-testid="subdomain-change-wizard-content-form">
					<Grid container>
						<Grid item xs={8}>
							<TextField
								prefix="123"
								id="subdomain"
								name="subdomain"
								helperText={
									<Box component="span">
										{helperText()}

										<Box mt={1} component="span">
											<Typography variant="subtitle2" color="textSecondary" component="span">
												<br />
												以下の文字を使うことができます
												<br />1 文字目：英小文字(a-z)
												<br />2 文字目以降：英小文字(a-z)、数字(0-9)、記号(-_)
											</Typography>
										</Box>
									</Box>
								}
								variant="outlined"
								error={errors.subdomain ? true : false}
								InputProps={{
									startAdornment: <InputAdornment position="start">https://</InputAdornment>,
									endAdornment: (
										<InputAdornment position="end">
											<Typography color="textSecondary" component="span">
												.collaboform.com
											</Typography>
											<Box ml={1} minWidth={30}>
												{checkAvailabilityIcon()}
											</Box>
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									required: true,
								}}
								label="サブドメイン"
								autoComplete="off"
								inputRef={register({
									required: { value: true, message: "サブドメインは必須です" },
								})}
								onBlur={onBlur}
								fullWidth
								data-testid="subdomain-change-wizard-content-input"
							/>
						</Grid>
					</Grid>

					<Box mt={2} alignContent="column">
						<Grid container spacing={1} justifyContent="space-between" alignItems="flex-end">
							<Grid item data-testid="subdomain-change-wizard-content-next-btn">
								<LoadingButton
									type="submit"
									color="primary"
									disabled={disabled}
									loading={[LoadingStatus.Pending, LoadingStatus.Loaded].includes(loading as any)}
									loadingIndicator={<CircularProgress size={22} color="inherit" />}
									size="large"
									variant="contained"
								>
									<Typography component="span" variant="body1" sx={{ fontWeight: "bold", fontSize: "125%" }}>
										次へ
									</Typography>
								</LoadingButton>
							</Grid>

							<Grid item>
								<Button onClick={onBackStep} variant="text" data-testid="subdomain-change-wizard-content-back-btn">
									<Typography component="span" variant="body2">
										ワークスペース名を修正する
									</Typography>
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default SubdomainChangeWizardContent;
