import { VFC } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import { LoadingStatus } from "../typings/CommonTypes";

type Props = {
	generateButton: {
		loading: LoadingStatus;
		onClick: () => void;
	};
};

const WorkspaceGetStartedContent: VFC<Props> = ({ generateButton: { loading, onClick } }) => {
	return (
		<Box data-testid="workspace-get-started-content-root">
			<Grid container spacing={1}>
				<Grid item xs={7}>
					<Box mr={1}>
						<Box>
							<Typography variant="h3">
								<strong>
									ワークスペースを
									<br />
									新規作成
								</strong>
							</Typography>
						</Box>
						<Box mt={1}>
							<Typography variant="body1" sx={{ fontSize: "1.25em" }}>
								コラボフローのフォームを公開するために必要な「ワークスペース」を作成します。下のボタンをクリックして新しいワークスペースの作成からはじめましょう。
							</Typography>
						</Box>
						<Box mt={2} data-testid="workspace-get-started-content-generate-btn">
							<LoadingButton
								color="primary"
								disabled={loading === LoadingStatus.Pending || loading === LoadingStatus.Loaded}
								endIcon={<ArrowForwardIcon />}
								loading={loading === LoadingStatus.Pending}
								loadingIndicator={<CircularProgress size={22} color="inherit" />}
								loadingPosition="end"
								onClick={onClick}
								size="large"
								variant="contained"
							>
								<Typography component="span" variant="body1" sx={{ fontWeight: "bold", fontSize: "125%" }}>
									ワークスペースを作成する
								</Typography>
							</LoadingButton>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={5}>
					<Box p={2}>
						<Box component="img" width="100%" src={`${process.env.PUBLIC_URL}/assets/images/get-started.svg`} alt="" />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default WorkspaceGetStartedContent;
