import { VFC } from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Grid, Typography, colors, Link } from "@mui/material";

import { ListedWorkspace } from "../typings/WorkspaceTypes";

import OverflowTooltip from "./OverflowTooltip";

type Props = {
	workspace: ListedWorkspace;
};

const WorkspaceListCardItem: VFC<Props> = ({ workspace }) => {
	const subdomain = workspace.link.split("https://")[1];

	return (
		<Link href={workspace.link} color="textPrimary" underline="none" data-testid="workspace-list-card-item-root">
			<Box
				p={3}
				sx={{
					transition: "0.1s",
					"&:hover": {
						background: colors.grey[100],
						transition: "0.3s",
						marginRight: -0.5,
					},
				}}
			>
				<Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
					<Grid item xs={11}>
						<Typography variant="body1" component="span" sx={{ fontSize: "1.4em" }}>
							<OverflowTooltip title={workspace.name} placement="top">
								<b>{workspace.name}</b>
							</OverflowTooltip>
						</Typography>
						<Typography variant="subtitle2" color="textSecondary" component="span">
							<OverflowTooltip title={subdomain} placement="top">
								<span>{subdomain}</span>
							</OverflowTooltip>
						</Typography>
					</Grid>
					<Grid item>
						<ArrowForwardIcon />
					</Grid>
				</Grid>
			</Box>
		</Link>
	);
};

export default WorkspaceListCardItem;
