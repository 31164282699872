import { Dispatch, SetStateAction, useState, VFC } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";

import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Grid, TextField, Typography } from "@mui/material";

import { patchWorkspace, PatchWorkspacesRequestBody } from "../apis/workspaces/patch";
import { LoadingStatus } from "../typings/CommonTypes";
import { WorkspaceInfo } from "../typings/WorkspaceTypes";
import { StringConverter } from "../utils/StringConverter";

import ToastContent from "./ToastContent";

type FormState = {
	workspace_name: string;
};

type Props = {
	workspace: WorkspaceInfo;
	setWorkspace: Dispatch<SetStateAction<WorkspaceInfo | undefined>>;
	onNextStep: () => void;
};

const WorkspaceNameChangeWizardContent: VFC<Props> = ({ workspace, setWorkspace, onNextStep }) => {
	const { getAccessTokenSilently } = useAuth0();
	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);
	const { errors, formState, handleSubmit, register, getValues, setValue } = useForm<FormState>({
		mode: "onBlur",
		defaultValues: {
			workspace_name: workspace.name,
		},
	});
	const { isDirty, isValid } = formState;

	const changeWorkspaceName = (): void => {
		const inputValue = getValues("workspace_name");
		if (inputValue) {
			setValue("workspace_name", new StringConverter().trim(inputValue));
		}
	};

	const onSubmit = handleSubmit(async (data: any) => {
		if (data.workspace_name === workspace.name) {
			onNextStep();
			return;
		}

		setLoading(LoadingStatus.Pending);

		const reqData: PatchWorkspacesRequestBody = {
			name: data.workspace_name,
		};

		await patchWorkspace(reqData, { auth_token: await getAccessTokenSilently(), workspace_id: workspace.id })
			.then((res) => {
				setWorkspace({ ...workspace, name: res.name });
				setValue("workspace_name", res.name);
				setLoading(LoadingStatus.Loaded);
				onNextStep();
			})
			.catch((reason) => {
				setLoading(LoadingStatus.Error);
				toast(<ToastContent subject="ワークスペースの更新に失敗しました" message={reason.message} />, {
					type: toast.TYPE.ERROR,
					autoClose: 5000,
				});
			});
	});

	const disabled = isDirty && ([LoadingStatus.Loaded, LoadingStatus.Pending, LoadingStatus.Error].includes(loading as any) || !isValid);

	return (
		<Box data-testid="workspace-name-change-wizard-content-root">
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={8}>
						<Box mr={1}>
							<Box>
								<Typography variant="h4">
									<strong>会社またはチームの名前を教えてください。</strong>
								</Typography>
							</Box>
							<Box py={1}>
								<Typography variant="body1" sx={{ fontSize: "1.25em" }}>
									これがあなたのコラボフォームワークスペースの名前になります。チームにとってわかりやすいものを選んでください。
								</Typography>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={4}>
						<Box p={2}>
							<Box component="img" width="100%" src={`${process.env.PUBLIC_URL}/assets/images/workspace.svg`} alt="" />
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box mt={1}>
				<form onSubmit={onSubmit} data-testid="workspace-name-change-wizard-content-form">
					<TextField
						id="workspace_name"
						name="workspace_name"
						helperText={
							<Typography variant="subtitle2" component="span">
								{errors.workspace_name?.message || "3 ～ 40 文字以内で素敵なワークスペース名を入力してください"}
							</Typography>
						}
						variant="outlined"
						label="ワークスペース名"
						autoComplete="off"
						error={errors.workspace_name ? true : false}
						InputLabelProps={{
							required: true,
						}}
						inputRef={register({
							required: { value: true, message: "ワークスペース名は必須です" },
							minLength: {
								value: 3,
								message: "ワークスペース名を 3 文字以上にしてください",
							},
							maxLength: {
								value: 40,
								message: "ワークスペース名を 40 文字以下にしてください",
							},
						})}
						onBlur={changeWorkspaceName}
						fullWidth
						data-testid="workspace-name-change-wizard-content-input"
					/>

					<Box mt={2} data-testid="workspace-name-change-wizard-content-next-btn">
						<LoadingButton
							type="submit"
							color="primary"
							disabled={disabled}
							loading={loading === LoadingStatus.Pending || loading === LoadingStatus.Loaded}
							loadingIndicator={<CircularProgress size={22} color="inherit" />}
							size="large"
							variant="contained"
						>
							<Typography component="span" variant="body1" sx={{ fontWeight: "bold", fontSize: "125%" }}>
								次へ
							</Typography>
						</LoadingButton>
					</Box>
				</form>
			</Box>
		</Box>
	);
};

export default WorkspaceNameChangeWizardContent;
