import { useEffect, useState, VFC } from "react";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";

import { Grid } from "@mui/material";

import { listWorkspaces } from "../apis/workspaces/list";
import ToastContent from "../components/ToastContent";
import WorkspaceSetupSuccessCard from "../components/WorkspaceSetupSuccessCard";
import MainTemplate from "../templates/MainTemplate";
import { LoadingStatus } from "../typings/CommonTypes";
import { ListedWorkspace } from "../typings/WorkspaceTypes";

const SetupWorkspaceSuccess: VFC<RouteComponentProps<{ workspace_id: string }>> = ({ match }) => {
	const workspace_id = match.params.workspace_id;

	const { getAccessTokenSilently, isAuthenticated } = useAuth0();
	const [workspace, setWorkspace] = useState<ListedWorkspace>();
	const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Initial);

	useEffect(() => {
		if (!isAuthenticated || loading !== LoadingStatus.Initial) return;

		(async () => {
			await listWorkspaces({ auth_token: await getAccessTokenSilently() })
				.then(({ workspaces }) => {
					const workspace = workspaces.filter((ws) => ws.id === workspace_id)[0];
					if (!workspace) {
						return Promise.reject(new Error("Not in list of joined workspaces."));
					}
					setWorkspace(workspace);
					setLoading(LoadingStatus.Loaded);
				})
				.catch((reason) => {
					console.error(reason);
					toast(<ToastContent subject="ワークスペースを取得できませんでした" message={reason.message} />, {
						type: "error",
						autoClose: false,
					});
					setLoading(LoadingStatus.Error);
				});
		})();
	}, [getAccessTokenSilently, isAuthenticated, loading, workspace_id]);

	return (
		<MainTemplate>
			<Grid container spacing={0} justifyContent="center" data-testid="setup-workspace-success-root">
				<Grid item xs={10}>
					<WorkspaceSetupSuccessCard elevation={4} loading={loading} workspace={workspace} data-testid="setup-workspace-success-card" />
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default SetupWorkspaceSuccess;
