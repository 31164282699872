import { VFC } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Button, Grid, Link, Typography } from "@mui/material";

const WorkspaceSigninContent: VFC = () => {
	return (
		<Box data-testid="workspace-signin-content-root">
			<Grid container spacing={1}>
				<Grid item xs={7}>
					<Box mr={1}>
						<Box>
							<Typography component="p" variant="h4" sx={{ fontWeight: "bold" }}>
								コラボフォームのご利用にはアカウントが必要です。
							</Typography>
						</Box>
						<Box mt={1}>
							<Typography variant="body1" sx={{ fontSize: "1.25em" }}>
								初めてご利用の場合は以下のボタンからアカウントを作成してください。
							</Typography>
						</Box>

						<Box mt={4} data-testid="workspace-signin-content-signup-btn">
							<Button component={RouterLink} to="/signin?m=signup" color="primary" size="large" variant="contained" fullWidth>
								<Typography component="span" variant="body1" sx={{ fontWeight: "bold", fontSize: "125%" }}>
									アカウントを作成する
								</Typography>
							</Button>
						</Box>
						<Box mt={2}>
							<Typography variant="body1">
								すでにお持ちの方は
								<Link
									component={RouterLink}
									to={{
										pathname: "/signin",
										search: `?redirect_url=${encodeURIComponent(window.location.origin + "/#/get-started")}`,
									}}
									sx={{ paddingLeft: 0.5, fontWeight: "bold" }}
								>
									サインインする
								</Link>
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={5}>
					<Box p={2}>
						<Box component="img" width="100%" src={`${process.env.PUBLIC_URL}/assets/images/signin.svg`} alt="" />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default WorkspaceSigninContent;
