import { VFC } from "react";

import { Box } from "@mui/material";

const MainHeaderContent: VFC = () => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center" mt={1} data-testid="main-header-content-root">
			<Box component="img" my={4} width={250} src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="" />
			{/* // FIXME: 右端にユーザーメニューのアイコンを追加 */}
		</Box>
	);
};

export default MainHeaderContent;
