import React, { useRef, useEffect, useState } from "react";

import { Box, Tooltip, TooltipProps } from "@mui/material";

const OverflowTooltip: React.VFC<TooltipProps> = ({ children, ...props }) => {
	const textElementRef = useRef<HTMLInputElement | null>(null);
	const [hoverStatus, setHover] = useState(false);

	function compareSize() {
		if (!textElementRef.current) {
			return;
		}
		const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
		setHover(compare);
	}

	// compare once and add resize listener on "componentDidMount"
	useEffect(() => {
		compareSize();
		window.addEventListener("resize", compareSize);
	}, []);

	// remove resize listener again on "componentWillUnmount"
	useEffect(() => {
		window.removeEventListener("resize", compareSize);
	}, []);

	return (
		<Tooltip {...props} disableHoverListener={!hoverStatus}>
			<Box ref={textElementRef} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
				{children}
			</Box>
		</Tooltip>
	);
};

export default OverflowTooltip;
