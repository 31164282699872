import { useEffect, useState, VFC } from "react";
import { Redirect } from "react-router";
import { toast } from "react-toastify";

import { useAuth0 } from "@auth0/auth0-react";

import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";

import { generateWorkspace } from "../apis/workspaces/GenerateWorkspace";
import { listWorkspaces } from "../apis/workspaces/list";
import { PostWorkspaceResponse } from "../apis/workspaces/post";
import ToastContent from "../components/ToastContent";
import WorkspaceGetStartedContent from "../components/WorkspaceGetStartedContent";
import WorkspaceListCard from "../components/WorkspaceListCard";
import WorkspaceSigninContent from "../components/WorkspaceSigninContent";
import MainTemplate from "../templates/MainTemplate";
import { LoadingStatus } from "../typings/CommonTypes";
import { ListedWorkspace } from "../typings/WorkspaceTypes";

const GetStarted: VFC = () => {
	const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
	const [workspaces, setWorkspaces] = useState<ListedWorkspace[] | null>(null);
	const [createWorkspaceLoading, setCreateWorkspaceLoading] = useState<LoadingStatus>(LoadingStatus.Initial);
	const [createdWorkspace, setCreatedWorkspace] = useState<PostWorkspaceResponse | undefined>(undefined);

	const createWorkspace = () => {
		(async () => {
			// ワークスペースリソースを作る
			setCreateWorkspaceLoading(LoadingStatus.Pending);
			await generateWorkspace({ auth_token: await getAccessTokenSilently() })
				.then((workspace) => {
					setCreatedWorkspace(workspace);
					setCreateWorkspaceLoading(LoadingStatus.Loaded);
				})
				.catch((reason) => {
					console.error(reason);
					toast(<ToastContent subject="ワークスペースが作成できませんでした" message={reason.message} />, {
						type: "error",
						autoClose: false,
					});
					setCreateWorkspaceLoading(LoadingStatus.Error);
				});
		})();
	};

	useEffect(() => {
		if (!isAuthenticated || workspaces) return;

		(async () => {
			await listWorkspaces({ auth_token: await getAccessTokenSilently() })
				.then(({ workspaces }) => {
					setWorkspaces(workspaces);
				})
				.catch((reason) => {
					console.error(reason);
					toast(<ToastContent subject="ワークスペースを取得できませんでした" message={reason.message} />, {
						type: "error",
						autoClose: false,
					});
					setWorkspaces([]);
				});
		})();
	}, [getAccessTokenSilently, isAuthenticated, workspaces]);

	if (!isAuthenticated && !isLoading) {
		return (
			<MainTemplate>
				<Grid container spacing={0} justifyContent="center" data-testid="get-started-page-signout-root">
					<Grid item xs={10}>
						<Box mb={6}>
							<WorkspaceSigninContent />
						</Box>
					</Grid>
				</Grid>
			</MainTemplate>
		);
	}

	if (createWorkspaceLoading === LoadingStatus.Loaded && createdWorkspace?.id) {
		return <Redirect to={`/setup-workspace/${createdWorkspace.id}`} />;
	}

	return (
		<MainTemplate>
			<Grid container spacing={0} justifyContent="center" data-testid="get-started-page-signin-root">
				<Grid item xs={10}>
					<Box mb={6}>
						<WorkspaceGetStartedContent generateButton={{ loading: createWorkspaceLoading, onClick: createWorkspace }} />
					</Box>
					<Divider />
				</Grid>
				<Grid item xs={8}>
					{workspaces === null ? (
						<Box display="flex" flexDirection="column" alignItems="center" mt={4}>
							<CircularProgress />
						</Box>
					) : workspaces.length ? (
						<Box>
							<Box display="flex" flexDirection="column" alignItems="center" mt={4}>
								<Box mb={2}>
									<Typography variant="h6">
										<strong>ワークスペースを開く</strong>
									</Typography>
								</Box>
							</Box>
							<WorkspaceListCard elevation={5} workspaces={workspaces} />
						</Box>
					) : (
						<></>
					)}
				</Grid>
			</Grid>
		</MainTemplate>
	);
};

export default GetStarted;
