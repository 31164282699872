import { useEffect, VFC } from "react";
import { useLocation } from "react-router-dom";

import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";

/**
 * リダイレクト先を選出する
 *
 * クエリ文字列のうち redirect_url をパースします。
 * 安全のためサブドメインを除くドメインが一致しなければパラメーターは無視されオリジンが採用されます。
 *
 * @param params
 * @returns
 */
const pickupRedirectUri = (params: URLSearchParams): string => {
	const redirectUri = params.get("redirect_url");
	if (redirectUri) {
		const hostname = new URL(redirectUri).hostname;
		if (hostname === window.location.host) {
			return redirectUri;
		}
	}
	return window.location.origin;
};

/**
 * サインイン方法
 *
 * クエリ文字列の m をパースします。
 * - "signup" 新規サインアップを優先表示します。
 * - "auto" 有効なセッションがあれば自動サインインを試み、セッションが無ければサインイン画面を表示します。
 * - 未指定 強制的にサインイン画面を表示します。
 *
 * @param params
 * @returns
 */
const pickupPromptAndScreenHint = (params: URLSearchParams): Pick<RedirectLoginOptions, "prompt" | "screen_hint"> => {
	const mode = params.get("m") || "";
	switch (mode) {
		case "signup":
			return { prompt: "login", screen_hint: "signup" };

		case "auto":
			return { prompt: undefined, screen_hint: undefined };
	}
	return { prompt: "login", screen_hint: "login" };
};

const LoginWithRedirect: VFC = () => {
	const { loginWithRedirect } = useAuth0();
	const { search } = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(search);
		const redirectUri = pickupRedirectUri(searchParams);
		const { prompt, screen_hint } = pickupPromptAndScreenHint(searchParams);
		const url = new URL(redirectUri);
		const returnTo = url.hash.replace(/^#/, "") || "/";

		const options: RedirectLoginOptions = {
			redirectUri,
			prompt,
			screen_hint,
			// FIXME: returnTo が指定されていないと redirectUri が適用されてもルートに戻るので暫定的に追加
			appState: { returnTo },
		};

		loginWithRedirect(options);
	}, [loginWithRedirect, search]);

	return <>リダイレクト中…</>;
};

export default LoginWithRedirect;
