import { Dispatch, SetStateAction, VFC } from "react";

import { Box, Card, CardContent, CardProps, CircularProgress, Grid, Typography } from "@mui/material";

import { LoadingStatus } from "../typings/CommonTypes";
import { WorkspaceInfo } from "../typings/WorkspaceTypes";

import WorkspaceSetupWizard from "./WorkspaceSetupWizard";

type Props = CardProps & {
	loading: LoadingStatus;
	workspace: WorkspaceInfo | undefined;
	setWorkspace: Dispatch<SetStateAction<WorkspaceInfo | undefined>>;
};

const WorkspaceSetupCard: VFC<Props> = ({ loading, workspace, setWorkspace, ...args }) => {
	return (
		<Card data-testid="workspace-setup-card-root" {...args}>
			<CardContent>
				<Box m={2}>
					{loading === LoadingStatus.Initial || loading === LoadingStatus.Pending ? (
						<Grid container direction="column" spacing={3} justifyContent="center" alignItems="center">
							<Grid item>
								<Typography variant="body1">ワークスペースのセットアップを準備しています</Typography>
							</Grid>
							<Grid item>
								<CircularProgress />
							</Grid>
						</Grid>
					) : loading === LoadingStatus.Error || !workspace ? (
						<Grid container direction="column" spacing={3} justifyContent="center" alignItems="center">
							<Grid item>
								<Typography variant="body1">ワークスペースが見つかりません</Typography>
							</Grid>
						</Grid>
					) : (
						<Box data-testid="setup-workspace-setup-card-setup-wizard">
							<WorkspaceSetupWizard workspace={workspace} setWorkspace={setWorkspace} />
						</Box>
					)}
				</Box>
			</CardContent>
		</Card>
	);
};

export default WorkspaceSetupCard;
