import { AxiosRequestConfig } from "axios";

import authConfig from "../../auth-config.json";

/** API リクエストのオプション */
export type ApiOptions = {
	/**Auth0 トークン */
	auth_token: string;

	/**
	 * ワークスペース ID
	 *
	 * ヘッダーに `x-workspace-id` として追加します
	 */
	workspace_id?: string;
};

/**
 * 特定のワークスペースにアクセスするための AxiosRequestConfig を生成します。
 *
 * @param options API リクエストのオプション
 * @param audience ベース URL
 * - 省略すると auth-config.json の `audience` を使用します
 */
export function generateAxiosRequestConfig(options: ApiOptions, audience = authConfig.audience): AxiosRequestConfig {
	const config: AxiosRequestConfig = {
		baseURL: audience,
		headers: {
			Authorization: "Bearer " + options.auth_token,
			"Content-Type": "application/json",
		},
	};
	// TODO: ワークスペース ID をトークンに含めたいが、実装にコストが高いため先送り
	options.workspace_id && (config.headers["x-workspace-id"] = options.workspace_id);

	return config;
}
