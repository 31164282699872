import axios from "axios";

import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

import { PostWorkspaceResponse } from "./post";

/**
 * ワークスペースを自動で新規作成します
 *
 * サブドメインはランダムで生成されます
 *
 * @param options API オプション
 */
export const generateWorkspace = async (options: ApiOptions): Promise<PostWorkspaceResponse> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.post("/workspaces/generate", {}, reqConfig);
	return data;
};
