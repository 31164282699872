import axios from "axios";

import { generateAxiosRequestConfig, ApiOptions } from "../misc/generateAxiosRequestConfig";

/**
 * サブドメイン名変更リクエスト
 */
export type ChangeSubdomainRequestBody = {
	name: string;
};

/**
 * サブドメイン名変更レスポンス
 */
export type ChangeSubdomainResponseBody = {
	name: string;
};

/**
 * サブドメイン名を変更します
 *
 * @param options API オプション
 */
export const changeSubdomain = async (reqData: ChangeSubdomainRequestBody, options: Required<ApiOptions>): Promise<ChangeSubdomainResponseBody> => {
	const reqConfig = generateAxiosRequestConfig(options);

	const { data } = await axios.post<ChangeSubdomainResponseBody>("/subdomains", reqData, reqConfig);

	return data;
};
