import { VFC } from "react";
import { Switch, Route, Redirect, HashRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { Box, ThemeProvider } from "@mui/material";

import ProtectedRoute from "./components/ProtectedRoute";
import { theme } from "./components/Theme";
import UserAuthProvider from "./components/UserAuthProvider";
import GetStarted from "./pages/GetStarted";
import Landing from "./pages/Landing";
import LoginWithRedirect from "./pages/LoginWithRedirect";
import SetupWorkspace from "./pages/SetupWorkspace";
import SetupWorkspaceSuccess from "./pages/SetupWorkspaceSuccess";

const Router: VFC = () => {
	return (
		<HashRouter>
			<Switch>
				<ProtectedRoute path="/setup-workspace/:workspace_id/success" component={SetupWorkspaceSuccess} />
				<ProtectedRoute path="/setup-workspace/:workspace_id" component={SetupWorkspace} />
				<ProtectedRoute path="/landing" component={Landing} />
				<Route path="/get-started" component={GetStarted} />
				<Route path="/signin" component={LoginWithRedirect} />
				<Route>
					<Redirect to="/landing" />
				</Route>
			</Switch>
		</HashRouter>
	);
};

export const App: VFC = () => {
	return (
		// TODO: store のプロバイダーの設置はこのあたり
		// <Provider store={store}>
		<UserAuthProvider>
			<ThemeProvider theme={theme}>
				<Box data-testid="app-root">
					<ToastContainer
						position="top-right"
						transition={Slide}
						autoClose={3000}
						hideProgressBar
						newestOnTop={false}
						pauseOnHover
						draggable={false}
					/>
					<Router />
				</Box>
			</ThemeProvider>
		</UserAuthProvider>
		// </Provider>
	);
};

export default App;
