import React from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { AppState } from "@auth0/auth0-react/dist/auth0-provider";

import config from "../auth-config.json";
import history from "../utils/history";

export interface UserAuthProviderProps {
	children?: React.ReactNode;
}

/**
 * react-router-domによるカスタムルーター対策したAuth0からのコールバック処理
 *
 * @param appState withAuthenticationRequired の第2引数で渡されたステート
 */
function handleRedirectCallback(appState: AppState) {
	// HACK: Auth0から戻った際に検索パラメーターとしてcodeとstateが残存してるので、強制的に取り去る
	window.history.replaceState(null, "", "/");

	// ハッシュ"#"以降の書き換え。これに応じて HashRouter が再評価されるらしい。
	const returnTo: string = appState?.returnTo || "/";
	history.replace(returnTo);
}

const UserAuthProvider: React.VFC<UserAuthProviderProps> = (props) => {
	return (
		<Auth0Provider
			domain={config.domain}
			clientId={config.clientId}
			audience={config.audience}
			redirectUri={window.location.origin}
			onRedirectCallback={handleRedirectCallback}
		>
			{props.children}
		</Auth0Provider>
	);
};

export default UserAuthProvider;
